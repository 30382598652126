import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";

import ProfileService from "../../Profile/service";
import {
  GET_ALL_CATEGORIES_URL,
  GET_POST_TYPES_URL,
  GET_MEDIA_LIST_URL,
  a_category_edit_url,
} from "../../../../_metronic/_helpers/Constants";
import { Table } from "../shared";
import { sortCaret } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  ActionsColumnFormatter,
  TitleColumnFormatter,
} from "../Policy/formatters";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import DeleteModal from "../shared/DeleteModal";
import MultipleDeleteModal from "../shared/MultipleDeleteModal";
import NotificationsModal from "../shared/NotificationsModal";
import TagModal from "../shared/NotificationsModal";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { useIntl } from "react-intl";
import { SearchTable } from "../shared/Table/SearchTable";
import CustomDatePicker from "../shared/DatePicker/CustomDatePicker";
import { getAllCategories } from "../../../_redux/Category/actions";

const CategoriesPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [getSearch, setSearch] = useState("");

  const service = new ProfileService();
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);

  const [filterBy, setFilterBy] = useState({
    type: "All",
    media: "All",
    created_at: "All",
  });

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });

  const [sizePerPage, setSizePerPage] = useState(20);

  const history = useHistory();

  const [currentCategory, setCurrentCategory] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [
    isMultipleDeleteModalVisible,
    setMultipleIsDeleteModalVisible,
  ] = useState(false);
  const [
    IsNotificationsModalVisible,
    setIsNotificationsModalVisible,
  ] = useState(false);
  const [IsTagModalVisible, setIsTagModalVisible] = useState(false);
  const [currentCategories, setCurrentCategories] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [dateValue, setDateValue] = useState(new Date());
  const [data, setData] = useState([]);

  const categoriesData = useSelector((state) => state?.category?.Index ?? []);
  const showLoader = useSelector((state) => state?.category?.showLoader ?? []);

  useEffect(() => {
    if (categoriesData?.data?.length) setData(categoriesData);
  }, [categoriesData]);

  const fetchCategories = async (page) => {
    let payload = {
      page: page - 1,
      size: sizePerPage,
      type: filterBy.type === "All" ? null : filterBy.type,
      media: filterBy.media === "All" ? null : filterBy.media,
      title: getSearch,
      created_at:
        filterBy.created_at === "All" || filterBy.created_at === "Invalid date"
          ? null
          : filterBy.created_at,
      order_by: orderBy.order_by === "" ? null : orderBy.order_by,
      order_direction:
        orderBy.order_direction === "" ? null : orderBy.order_direction,
    };
    for (let key in payload) {
      if (payload[key] === null) {
        delete payload[key];
      }
    }

    dispatch(getAllCategories(payload));
  };
  const { isLoading, isFetching } = useQuery(
    [page, filterBy, orderBy, sizePerPage, getSearch],
    () => fetchCategories(page),
    { keepPreviousData: true }
  );

  const postTypes = useQuery(
    ["postTypes"],
    async () => (await service.post(GET_POST_TYPES_URL)).data,
    { keepPreviousData: true }
  );

  const fetchMediaList = async () => {
    const response = await service.post(GET_MEDIA_LIST_URL, {
      post_type: filterBy.type === "All" ? null : filterBy.type,
    });
    return response.data;
  };

  const mediaTypes = useQuery(
    ["mediaTypes", filterBy.type],
    () => fetchMediaList(),
    { keepPreviousData: true }
  );

  const onSort = (field, order) => {
    const sortField = (field) => {
      switch (field) {
        case "type.name":
          return "type";
        case "media.name":
          return "medij_id";
        default:
          return field;
      }
    };
    setOrderBy({
      order_by: sortField(field),
      order_direction: order,
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      sort: true,
      sortCaret: sortCaret,
      // formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_category_edit_url}/${row.id}`);
        },
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "type.name",
      text: intl.formatMessage({ id: "TH_TYPE" }),
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "media.name",
      text: intl.formatMessage({ id: "TH_MEDIA" }),
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_category_edit_url}/${row.id}`);
        },
        openDeleteModal: (row) => {
          setCurrentCategory(row);
          setIsDeleteModalVisible(true);
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  // if (isLoading) return null;
  const paginationOptions = {
    totalSize: data.totalCount ?? 0,
    sizePerPage,
    page,
  };

  const deleteSelectedItems = (selectedIds) => {
    setMultipleIsDeleteModalVisible(true);
    setCurrentCategories(selectedIds);
  };

  const searchByCreatedDate = (date) => {
    setStartDate(date);
    setFilterBy({
      ...filterBy,
      created_at: moment(date).format("YYYY-MM-DD"),
    });
  };

  return (
    <Card>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        currentItem={currentCategory}
        setCurrentItem={setCurrentCategory}
        itemType="categories"
      />
      <MultipleDeleteModal
        isVisible={isMultipleDeleteModalVisible}
        setIsVisible={setMultipleIsDeleteModalVisible}
        currentItems={currentCategories}
        setCurrentItems={setCurrentCategories}
        setSelectedRows={setAllIds}
        itemType="categories"
      />
      <NotificationsModal
        isVisible={IsNotificationsModalVisible}
        setIsVisible={setIsNotificationsModalVisible}
        currentItem={currentCategory}
        setCurrentItem={setCurrentCategory}
      />
      <TagModal
        isVisible={IsTagModalVisible}
        setIsVisible={setIsTagModalVisible}
        currentItem={currentCategory}
        setCurrentItem={setCurrentCategory}
      />
      <CardHeader title={intl.formatMessage({ id: "MENU.CATEGORY" })} sticky>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary ml-5"
            onClick={() => {
              history.push(a_category_edit_url);
            }}
          >
            {intl.formatMessage({ id: "MENU.NEW" })}{" "}
            {intl.formatMessage({ id: "MENU.CATEGORY" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col-lg-3">
            {!postTypes.isLoading && (
              <select
                className="form-control"
                name="type"
                placeholder="Filter by Types"
                onChange={(e) => {
                  setFilterBy({
                    ...filterBy,
                    type: e.target.value,
                  });
                  setPage(1);
                }}
                value={filterBy.type}
              >
                <option value={"All"}>All</option>
                {postTypes?.data?.data?.map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
              </select>
            )}
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
              {intl.formatMessage({ id: "TH_TYPE" })}
            </small>
          </div>
          <div className="col-lg-3">
            {!mediaTypes.isLoading && (
              <>
                <select
                  className="form-control"
                  name="media"
                  placeholder="Filter by Media"
                  onChange={(e) => {
                    setFilterBy({
                      ...filterBy,
                      media: e.target.value,
                    });
                    setPage(1);
                  }}
                  value={filterBy.media}
                >
                  <option value={"All"}>All</option>
                  {mediaTypes?.data?.data?.map((media) => (
                    <option key={media.id} value={media.id}>
                      {media.name}
                    </option>
                  ))}
                </select>
                <small className="form-text text-muted">
                  <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
                  {intl.formatMessage({ id: "MENU.MEDIA" })}
                </small>
              </>
            )}
          </div>
          <div className="col-lg-3">
            <CustomDatePicker id={"category"} setDateValue={setDateValue} />

            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Created Date
            </small>
          </div>
          <SearchTable setSearch={setSearch} />
        </div>
        {allIds.length > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>{" "}
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
          </div>
        )}

        <Table
          // showLoader={showLoader}
          data={data?.data ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setSizePerPage={setSizePerPage}
          setPage={setPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
          // isFetching={isFetching}
        />
      </CardBody>
    </Card>
  );
};

export default CategoriesPage;
