import React, { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce";
import { useIntl } from "react-intl";


export const SearchTable = (props) => {
  const intl = useIntl();
  const [userQuery, setUserQuery] = useState("");
  //   const [getSearch, setSearch] = useState("");

  const updateQuery = () => {
    // A search query api call.
    // console.log(userQuery);
    props.setSearch(userQuery);
    // sendQuery(userQuery);
  };

  const delayedQuery = useCallback(debounce(updateQuery, 200), [userQuery]);

  const onChange = (e) => {
    setUserQuery(e.target.value);
  };

  useEffect(() => {
    delayedQuery();

    // Cancel the debounce on useEffect cleanup.
    return delayedQuery.cancel;
  }, [userQuery, delayedQuery]);

 

  return (
    <>
      <div className="col-lg-3">
        <div className="input-icon input-icon-right">
          <input
            type="text"
            className="form-control"
            placeholder={intl.formatMessage({
              id: "COMMON.SEARCH",
            })}
            onChange={onChange}
            value={userQuery}
          />
          <span>
            <i className="flaticon2-search-1 icon-md" />
          </span>
        </div>
      </div>
    </>
  );
};
