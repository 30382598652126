/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useRef, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import { useMutation, useQuery } from "react-query";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import * as toasts from "../../ECommerce/_redux/toasts/toastsActions";
import { useIntl } from "react-intl";

import {
  Input,
  Select,
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  GET_ALL_CATEGORIES_URL,
  EDIT_CATEGORY_URL,
  SAVE_CATEGORY_URL,
  GET_POST_TYPES_URL,
  GET_MEDIA_LIST_URL,
  a_category_url,
} from "../../../../_metronic/_helpers/Constants";
import ProfileService from "../../Profile/service";
import {
  editCategoryAction,
  saveCategoryAction,
} from "../../../_redux/Category/actions";

const EditSchema = Yup.object().shape({
  name: Yup.string().required(),
  // slug: Yup.string().required(),
  type: Yup.string().required(),
  media: Yup.string().required(),
});

const CategoryEditPage = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [currentCategory, setCurrentCategory] = useState(null);
  const [type, setType] = useState(null);
  const history = useHistory();
  const service = new ProfileService();


  const params = useParams();
  useEffect(() => {
    const fetchCategory = async () => {
      if (!params.id) return;
      try {
        const response = await service.post(GET_ALL_CATEGORIES_URL, {
          id: params.id,
          page: 0,
          size: 20,
        });
        const category = response.data.data[0];
        setCurrentCategory(category);
        setType(category.type?.id || null);
      } catch (error) {}
    };

    fetchCategory();
  }, [params]);

  const saveCategory = async (values) => {
    const id = +params.id || null;
    const payload = id ? { ...values, id } : { ...values };
    if (id) {
      dispatch(editCategoryAction(payload, intl, toasts, history));
    } else {
      dispatch(saveCategoryAction(payload, intl, toasts, history));
    }
  };

  const mutation = useMutation(saveCategory, {
    onSuccess: (data) => {
      dispatch(
        toasts.showToast(
          data.data.status,
          intl.formatMessage({ id: data.data.title })
        )
      );
      dispatch(toasts.hideToast());
    },
    onError: (error) => {
      dispatch(
        toasts.showToast(
          error.response.data.status,
          intl.formatMessage({
            id: error.response.data.details || error.response.data.errors[0],
          })
        )
      );
      dispatch(toasts.hideToast());
    },
  });

  const backToCategoryList = () => {
    history.push(a_category_url);
  };
  const btnRef = useRef();

  const saveCategoryClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const postTypes = useQuery(
    ["postTypes"],
    async () => (await service.post(GET_POST_TYPES_URL)).data,
    { keepPreviousData: true }
  );

  const mediaTypes = useQuery(
    ["mediaTypes", type],
    async () =>
      (await service.post(GET_MEDIA_LIST_URL, { post_type: type })).data,
    { keepPreviousData: true }
  );



  if (params.id && !currentCategory) {
    return null;
  }

  const initialValues = {
    name: currentCategory ? currentCategory.name : "",
    // slug: currentCategory ? currentCategory.slug : "",
    type: currentCategory ? currentCategory.type?.id : "",
    media: currentCategory ? currentCategory.media?.id : "",
  };

  const formTitle = params.id
    ? intl.formatMessage({ id: "MENU.OPTIONS.EDIT" }) +
      " " +
      intl.formatMessage({ id: "MENU.CATEGORY" })
    : intl.formatMessage({ id: "Add_new" }) +
      " " +
      intl.formatMessage({ id: "MENU.CATEGORY" });

  return (
    <Card>
      <CardHeader title={formTitle}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToCategoryList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            {intl.formatMessage({ id: "AUTH.GENERAL.BACK_BUTTON" })}
          </button>
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={saveCategoryClick}
          >
            {intl.formatMessage({ id: "MENU.OPTIONS.SAVE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
          <Formik
            initialValues={initialValues}
            validationSchema={EditSchema}
            enableReinitialize
            onSubmit={async (values) => {
              try {
                await mutation.mutateAsync(values);
                history.push(a_category_url);
              } catch (error) {
                console.error(error);
              }
            }}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {({ handleSubmit, values, setFieldValue, errors }) => (
              <>
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-12">
                      <Field
                        type="text"
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label="Name"
                        withFeedbackLabel={false}
                      />
                      {errors?.name && (
                        <span className="error">{errors?.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      {!postTypes.isLoading && (
                        <Select
                          name="type"
                          id="type"
                          label="Type"
                          withFeedbackLabel={false}
                          onChange={(e) => {
                            setType(+e.target.value || null);
                            setFieldValue("type", +e.target.value || "");
                          }}
                        >
                          <option value="''">Select</option>
                          {postTypes?.data?.data?.map((type) => (
                            <option key={type.id} value={type.id}>
                              {type.name}
                            </option>
                          ))}
                        </Select>
                      )}
                      {errors?.type && (
                        <span className="error">{errors?.type}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      {!mediaTypes.isLoading && (
                        <Select
                          name="media"
                          label="Media"
                          withFeedbackLabel={false}
                          disabled={!type}
                          onChange={(e) => {
                            setFieldValue("media", +e.target.value || "");
                          }}
                        >
                          <option value="''">Select</option>
                          {(type ? mediaTypes?.data?.data : []).map((media) => (
                            <option key={media.id} value={media.id}>
                              {media.name}
                            </option>
                          ))}
                        </Select>
                      )}
                      {errors?.media && (
                        <span className="error">{errors?.media}</span>
                      )}
                      {type && !mediaTypes.data?.data.length && (
                        <small className="form-text text-muted">
                          No Media found for selected type.
                        </small>
                      )}
                    </div>
                  </div>
                  <button
                    type="submit"
                    style={{ display: "none" }}
                    ref={btnRef}
                    onSubmit={() => handleSubmit()}
                  ></button>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </CardBody>
    </Card>
  );
};

export default CategoryEditPage;
