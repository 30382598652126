import React, { useEffect } from 'react';
import { SwipeableDrawer, Container } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux'
import { openAsideModal, closeAsideModal } from "../../../_redux/arhiva/ArhivaActions";
import { DateRange, ToggleButton, MultiList } from '@appbaseio/reactivesearch';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
export default function ArhivaSidebar() {
    const dispatch = useDispatch();
    const location = useLocation();
    const { arhivaState } = useSelector(
        (state) => ({ arhivaState: state.arhiva })
    )
    const { folderState } = useSelector(
        (state) => ({ folderState: state.folders })
    )

    useEffect(() => {
        if (location.pathname.includes('/archive') && folderState?.activeFolder?.title) {
            dispatch(openAsideModal())
        }

    }, [folderState])

    return (
        <div>
            <SwipeableDrawer
                anchor="right"
                style={{ display: (arhivaState.open ? 'inherit' : 'none') }}
                open={true}
                onClose={() => dispatch(closeAsideModal())}
                onOpen={() => dispatch(openAsideModal())}
            >
                <div
                    className="sidebarfilters right"
                    role="presentation"
                >
                    <ToggleButton
                        componentId="toggle"
                        title="Tip medija"
                        dataField="type"
                        react={{ and: ["s", "categories", "sorting", "dateRangeArhiva", "arhivalist"] }}
                        URLParams={true}
                        data={[
                            { label: 'internet', value: 'internet' },
                            { label: 'elektronski', value: 'elektronski' },
                            { label: 'stampani', value: 'stampani' }
                        ]}
                    />
                    <DateRange
                        react={{ 
                            and: ["s", "categories", "sorting", "toggle", "arhivalist"] 
                        }}
                        componentId="dateRangeArhiva"
                        dataField="displaydate"
                        queryFormat="date"

                        title="Vremenski opseg"
                        placeholder={{
                            start: 'Start Date 123',
                            end: 'End Date 123',
                        }}
                        initialMonth={new Date( moment().subtract(1, 'months'))}
                        numberOfMonths={2}
                        showClear={true}
                        showFilter={true}
                        filterLabel="Date"
                        URLParams={false}
                        innerClass={{
                            title: 'search-title-test',
                            'input-container': 'search-input-test'
                        }}
                    />
                    <MultiList
                        componentId="categories"
                        dataField="kategorija_str"
                        URLParams={true}
                        react={{
                            and: ["s", "toggle", "sorting", "dateRangeArhiva", "arhivalist"]
                        }}
                        title="Kategorije"
                    />
                    <MultiList
                        componentId="sorting"
                        URLParams={true}
                        title="Sortiranje"
                        dataField="date_i"
                        react={{
                            and: ["s", "arhivalist", "toggle", "categories", "dateRangeArhiva"]
                        }}
                        transformData={(data) => {
                            return data.sort(function (a, b) {
                                return b.key - a.key
                            });

                        }}
                        renderItem={(label, count, isSelected) => {
                            return (
                                <div>
                                    {moment.unix(label.toString()).format("DD-MMMM-YYYY")}
                                    < span style={{
                                        marginLeft: 5, color: isSelected ? 'red' : 'dodgerblue'
                                    }}>
                                        {count}
                                    </span>
                                </div >
                            )
                        }}
                    />
                </div >
            </SwipeableDrawer>
        </div>
    );
}
