import { combineReducers } from "redux";

export const setAllSectionsData = (payload) => {
  return {
    type: "SET_SECTIONS_DATA",
    payload: payload,
  };
};

const Index = (state = [], action) => {
  const { payload, toasts, intl, id, ids } = action;
  switch (action.type) {
    case "SET_SECTIONS_DATA":
      return payload;
    case "DELETE_SECTION_FROM_REDUX":
      const deleteData = state.data.filter((x) => (x.id === id ? false : x));
      const newState = {
        ...state, // Copy the original state
        data: deleteData, // Replace the data array with the modified one
        totalCount:state.totalCount -1
      };
      return newState;
    case "DELTE_MULTI_SECTION_FROM_REDUX":
      const deleteMultiData = state.data.filter((x) =>
        ids.includes(x.id) ? false : x
      );

      //   setAllIds([]);
      const newDeletedState = {
        ...state, // Copy the original state
        data: deleteMultiData, // Replace the data array with the modified one
        totalCount:state.totalCount - ids.length
      };
      return newDeletedState;
    default:
      return state;
  }
};
const showLoader = (state = false, action) => {
  const { type, showLoader } = action;
  switch (type) {
    case "SHOW_LOADER":
      return showLoader;
    default:
      return state;
  }
};
export const reducer = combineReducers({
  Index,
  showLoader,
});
