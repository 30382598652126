import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as moment from "moment";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { Table } from "../shared";
import {
  GET_SECTION_LIST_URL,
  a_policy_edit_url,
  a_policy_url,
} from "../../../../_metronic/_helpers/Constants";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import ProfileService from "../../Profile/service";
import DeleteModal from "../shared/DeleteModal";
import MultipleDeleteModal from "../shared/MultipleDeleteModal";
import PublishModal from "../shared/PublishModal";
import { ActionsColumnFormatter, TitleColumnFormatter } from "./formatters";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import { sortCaret } from "../../../../_metronic/_helpers";
import { getAllPoliciesData } from "../../../_redux/policy/actions";

const PolicyPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const service = new ProfileService();
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);

  const [filterBy, setFilterBy] = useState({
    type: "All",
    status: "All",
    category: "All",
  });

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });

  const [sizePerPage, setSizePerPage] = useState(20);

  const history = useHistory();

  const [currentPolicy, setCurrentPolicy] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [
    isMultipleDeleteModalVisible,
    setMultipleIsDeleteModalVisible,
  ] = useState(false);
  const [isPublishModalVisible, setPublishModalVisible] = useState(false);
  const [publishType, setPublishType] = useState(true);
  const [currentPolicies, setCurrentPolicies] = useState(null);

  const [data, setData] = useState([]);
  const policiesData = useSelector((state) => state?.policies?.Index ?? []);
  const showLoader = useSelector((state) => state?.policies?.showLoader ?? []);

  useEffect(() => {
    setData(policiesData);
  }, [policiesData]);

  const fetchData = (page) => {
    let payload = {
      page: page - 1,
      size: sizePerPage,
      type_id: filterBy.type === "All" ? null : filterBy.type,
      status: filterBy.status === "All" ? null : filterBy.status,
      category_id: filterBy.category === "All" ? null : filterBy.category,
      order_by: orderBy.order_by === "" ? null : orderBy.order_by,
      order_direction:
        orderBy.order_direction === "" ? null : orderBy.order_direction,
    };
    dispatch(getAllPoliciesData(payload));

    // const response = await service.post(GET_ALL_POLICIES_URL, {
    //   page: page - 1,
    //   size: sizePerPage,
    //   type_id: filterBy.type === "All" ? null : filterBy.type,
    //   status: filterBy.status === "All" ? null : filterBy.status,
    //   category_id: filterBy.category === "All" ? null : filterBy.category,
    //   order_by: orderBy.order_by === "" ? null : orderBy.order_by,
    //   order_direction:
    //     orderBy.order_direction === "" ? null : orderBy.order_direction,
    // });
    // return response.data;
  };

  const fetchCategories = async () => {
    const response = await service.post(GET_SECTION_LIST_URL, {
      type_id: filterBy.type === "All" ? null : filterBy.type,
    });
    return response.data;
  };

  const categories = useQuery(
    ["categories", filterBy.type],
    () => fetchCategories(),
    {
      keepPreviousData: true,
    }
  );

  const { isLoading, isFetching } = useQuery(
    [page, filterBy, orderBy, sizePerPage],
    () => fetchData(page),
    {
      keepPreviousData: true,
    }
  );

  const onSort = (field, order) => {
    setOrderBy({
      order_by: field,
      order_direction: order,
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "title",
      text: intl.formatMessage({ id: "TH_TITLE" }),
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_policy_edit_url}/${row.id}`);
        },
      },
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "category_name",
      text: intl.formatMessage({ id: "TH_CATEGORY" }),
      sort: true,
      onSort: onSort,
      sortCaret: sortCaret,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "type_name",
      text: intl.formatMessage({ id: "TH_TYPE" }),
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "status",
      text: intl.formatMessage({ id: "TH_STATUS" }),
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      formatter: (status) => {
        return status === 0 || status === "" ? (
          <span className="label label-lg label-light-danger label-inline">
            Disabled
          </span>
        ) : (
          <span className="label label-lg label-light-success label-inline">
            Enabled
          </span>
        );
      },
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy");
      },
      headerStyle: { verticalAlign: "initial" },
      // formatter: columnFormatters.ColorColumnFormatter,
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy");
      },
      headerStyle: { verticalAlign: "initial" },
      // formatter: columnFormatters.ColorColumnFormatter,
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_policy_edit_url}/${row.id}`);
        },
        openDeleteModal: (row) => {
          setCurrentPolicy(row);
          setIsDeleteModalVisible(true);
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];
  // if (isLoading) return null;
  const paginationOptions = {
    totalSize: data.totalCount ?? 0,
    sizePerPage,
    page,
  };

  const deleteSelectedItems = (selectedIds) => {
    setMultipleIsDeleteModalVisible(true);
    setCurrentPolicies(selectedIds);
  };

  const publishSelectedItems = (selectedIds, type) => {
    setPublishModalVisible(true);
    setCurrentPolicies(selectedIds);
    setPublishType(type);
  };

  return (
    <Card>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        currentItem={currentPolicy}
        setCurrentItem={setCurrentPolicy}
        itemType="policy"
      />
      <MultipleDeleteModal
        isVisible={isMultipleDeleteModalVisible}
        setIsVisible={setMultipleIsDeleteModalVisible}
        currentItems={currentPolicies}
        setCurrentItems={setCurrentPolicies}
        itemType="policy"
        setAllIds={setAllIds}
      />
      <PublishModal
        isVisible={isPublishModalVisible}
        setIsVisible={setPublishModalVisible}
        currentItems={currentPolicies}
        setCurrentItems={setCurrentPolicies}
        publishType={publishType}
        itemType="policy"
      />
      <CardHeader title={intl.formatMessage({ id: "Policy" })} sticky={true}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary ml-5"
            onClick={() => {
              history.push(`${a_policy_url}/edit`);
            }}
          >
            {intl.formatMessage({ id: "PRIVACY.CREATE" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col-lg-2">
            <select
              className="form-control"
              name="type"
              placeholder="Filter by Type"
              onChange={(e) => {
                setFilterBy({
                  ...filterBy,
                  category: "All",
                  type: e.target.value,
                });
                setPage(1);
              }}
              value={filterBy.type}
            >
              <option value={"All"}>All</option>
              <option value={5}>Privacy</option>
              <option value={6}>Legal</option>
              <option value={7}>FAQ</option>
            </select>
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
              {intl.formatMessage({ id: "TH_TYPE" })}
            </small>
          </div>
          <div className="col-lg-2">
            {!categories.isLoading && (
              <select
                className="form-control"
                name="category"
                placeholder="Filter by Category"
                onChange={(e) => {
                  setFilterBy({
                    ...filterBy,
                    category: e.target.value,
                  });
                  setPage(1);
                }}
                value={filterBy.category}
              >
                <option value={"All"}>All</option>
                {categories.data?.data?.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
            )}
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
              {intl.formatMessage({ id: "MENU.CATEGORY" })}
            </small>
          </div>
          <div className="col-lg-2">
            <select
              className="form-control"
              name="type"
              placeholder="Filter by Status"
              onChange={(e) => {
                setFilterBy({
                  ...filterBy,
                  status: e.target.value,
                });
                setPage(1);
              }}
              value={filterBy.status}
            >
              <option value={"All"}>
              {intl.formatMessage({ id: "OPTIONS.PRIVACY_ALL" })}
              </option>
              <option value={1}>
              {intl.formatMessage({ id: "OPTIONS.ENABLED" })}
                </option>
              <option value={0}>
              {intl.formatMessage({ id: "OPTIONS.DISABLED" })}
                
                </option>
            </select>
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> {" "}
              {intl.formatMessage({ id: "TH.FILTER_BY.STATUS" })}
            </small>
          </div>
        </div>

        {allIds.length > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm"
              onClick={() => publishSelectedItems(allIds, true)}
            >
              <i className="fa fa-stream"></i> Publish
            </button>
            &nbsp;
            <button
              type="button"
              className="btn btn-light-primary font-weight-bolder font-size-sm mr-5"
              onClick={() => publishSelectedItems(allIds, false)}
            >
              <i className="fa fa-sync-alt"></i> Unpublish
            </button>
          </div>
        )}

        <Table
          // showLoader={showLoader}
          data={data.data ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setPage={setPage}
          isFetching={isFetching}
          setSizePerPage={setSizePerPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
      </CardBody>
    </Card>
  );
};

export default PolicyPage;
