import React, { useState, useEffect } from "react";
import moment from "moment";

const CustomDatePicker = (props) => {
  const [id, setId] = useState(null);
  useEffect(() => {
    setId(window.$(`#${props.id}`));
  }, []);

  // useEffect(() => {
  //   setId(window.$(`#${props.id}`));
  // }, []);
  useEffect(() => {
    if (id) {
      id.datepicker();
    }
  }, [id]);

  useEffect(() => {
    if (id != null) {
      id.on("change", function(e) {
        props.setDateValue(e.target.value);
      });
    }
  }, [id]);

  return (
    <>
      <div
        className="input-group date"
        id="kt_datetimepicker_2"
        data-target-input="nearest"
      >
        <input
          type="text"
          className="form-control datetimepicker-input"
          placeholder="Select date "
          data-target={props?.id}
          id={props.id}
          value={
            props?.dateValue
              ? moment.utc(props?.dateValue).format("MM/DD/YYYY")
              : ""
          }
          onKeyDown={() => {
            props.setDateValue(null);
          }}
        />
        <div
          className="input-group-append"
          data-target={props?.id}
          data-toggle="datetimepicker"
        >
          <span className="input-group-text">
            <i className="ki ki-calendar" />
          </span>
        </div>
      </div>
    </>
  );
};

export default CustomDatePicker;
