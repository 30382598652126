import axios from "axios";
import { ARHIVA_REST_URL } from "../../../environments/environment";
import {
  SAVE_CATEGORY_URL,
  EDIT_CATEGORY_URL,
  DELETE_CATEGORY_URL,
  DELETE_MULTIPLE_CATEGORIES_URL,
} from "../../../_metronic/_helpers";

export const getAllCategoriesDataCall = (action) => {
  const { payload } = action;
  const queryParameters = new URLSearchParams(payload).toString();
  return axios.get(`${ARHIVA_REST_URL}api/categories?${queryParameters}`);
};
export const saveCategoryDataCall = (data) => {
  return axios.post(SAVE_CATEGORY_URL, data);
};

export const editCategoryDataCall = (actions) => {
  let data = actions.payload;
  return axios.post(`${EDIT_CATEGORY_URL}`, data);
};

export const deleteCategoryDataCall = (id) => {
  return axios.delete(`${DELETE_CATEGORY_URL}${id}`);
};

export const multiDeleteCategoryCall = (action) => {
  let data = {};
  data.ids = action.payload;
  return axios.post(`${DELETE_MULTIPLE_CATEGORIES_URL}`, data);
};
