import { ARHIVA_REST_URL } from "../../environments/environment";

// ResetPassword.js, Registration.js
export const pwd_min_lenght = 6;
export const pwd_max_lenght = 16;
export const pwd_caps = true; // 1 - use CAPS letter in pwd, 0 don't use
export const pwd_char = true; // 1 - use SPECIAL CHARS in pwd, 0 don't use
export const msg_min_lenght = 100;
export const msg_max_lenght = 1000;
export const max_input = 50;
export const min_input = 3;
export const usrn_min_lenght = 5;
export const usrn_max_lenght = 15;

//Login.js, ForgotPassword.js, ResetPassword.js
export const LOGIN_URL = ARHIVA_REST_URL + "api/login";
export const REGISTER_URL = ARHIVA_REST_URL + "api/register";
export const REQUEST_PASSWORD_URL =
  ARHIVA_REST_URL + "api/password/request-new";
export const SET_NEW_PASSWORD_URL = ARHIVA_REST_URL + "api/password/reset";
export const CONTACT_URL = ARHIVA_REST_URL + "api/contact";
export const ME_URL = ARHIVA_REST_URL + "api/user/profile";
export const VALIDATE_URL = ARHIVA_REST_URL + "api/user/search";
export const FILES_ARHIVA_URL = "http://files.arhiva.me/";
export const PERSONAL_INFORMATION_URL =
  ARHIVA_REST_URL + "api/user/personal-information";
export const ACCOUNT_INFORMATION_URL =
  ARHIVA_REST_URL + "api/user/account-information";
export const CHANGE_PASSWORD_URL = ARHIVA_REST_URL + "api/user/change-password";
export const EMAIL_SETTINGS_URL = ARHIVA_REST_URL + "api/user/email-settings";
export const GET_ALL_POLICIES_URL = ARHIVA_REST_URL + "api/policies";
export const GET_ALL_CATEGORIES_URL = ARHIVA_REST_URL + "api/categories";
export const SAVE_POLICY_URL = ARHIVA_REST_URL + "api/policies/add";
export const EDIT_POLICY_URL = ARHIVA_REST_URL + "api/policies/edit";
export const DELETE_POLICY_URL = ARHIVA_REST_URL + "api/policies/delete";
export const SAVE_CATEGORY_URL = ARHIVA_REST_URL + "api/categories/add";
export const EDIT_CATEGORY_URL = ARHIVA_REST_URL + "api/categories/edit";
export const DELETE_CATEGORY_URL = ARHIVA_REST_URL + "api/categories/delete/";
export const DELETE_MULTIPLE_CATEGORIES_URL =
  ARHIVA_REST_URL + "api/categories/bulk-delete";
export const GET_ALL_USERS = ARHIVA_REST_URL + "api/users";
export const DELETE_MULTIPLE_POLICY_URL =
  ARHIVA_REST_URL + "api/policies/multy-delete";
export const PUBLISH_MULTIPLE_POLICY_URL =
  ARHIVA_REST_URL + "api/policies/multy-edit";
export const DELETE_MULTIPLE_USER_URL =
  ARHIVA_REST_URL + "api/users/multy-delete";
export const PUBLISH_MULTIPLE_USER_URL =
  ARHIVA_REST_URL + "api/users/multy-edit";
export const DELETE_USER_URL = ARHIVA_REST_URL + "api/user/delete";
export const SAVE_SECTION_URL = ARHIVA_REST_URL + "api/section/add";
export const EDIT_SECTION_URL = ARHIVA_REST_URL + "api/section/update";
export const DELETE_SECTION_URL = ARHIVA_REST_URL + "api/section/delete/";
export const DELETE_MULTIPLE_SECTIONS_URL =
  ARHIVA_REST_URL + "api/section/bulk-delete";

export const GET_ALL_SOURCES_URL = ARHIVA_REST_URL + "api/source/list";
export const SAVE_SOURCE_URL = ARHIVA_REST_URL + "api/source/create";
export const EDIT_SOURCE_URL = ARHIVA_REST_URL + "api/source/update";
export const DELETE_SOURCE_URL = ARHIVA_REST_URL + "api/source/delete/";
export const DELETE_MULTIPLE_SOURCES_URL =
  ARHIVA_REST_URL + "api/source/bulk-delete";

export const GET_ALL_ROLES_URL = ARHIVA_REST_URL + "api/roles";

export const dashboard_url = "/dashboard";
export const clipping_media_url = "kliping";
export const archive_url = "archive";
export const monitoring_url = "monitoring";
export const announcements_url = "announcements";
export const searches_url = "pretraga";
export const folders_url = "folders";
export const pridruzeno_pretragama_t = "pridruzeno_pretragama_t";
export const pridruzeno_folderima_t = "pridruzeno_folderima_t";
export const licni = "licni";
export const REGISTRATION_STRING = "register";
export const error_url = "/error";
export const legal_url = "/legal";
export const privacy_url = "/privacy";
export const helpcenter_url = "/helpcenter";
export const userprofile_url = "/user";
export const contactpage_url = "/contact";
export const admin_url = "/admin";
export const a_policy_url = admin_url + "/policy";
export const a_policy_edit_url = a_policy_url + "/edit";
export const a_users_url = admin_url + "/users";
export const a_institute_url = admin_url + "/institute";
export const a_institute_activity_url = admin_url + "/institution-activity";
export const a_institute_activity_edit_url = a_institute_activity_url + "/edit";

export const a_subject_url = admin_url + "/subjects";
export const a_subjects_edit_url = a_subject_url + "/edit";

export const a_users_url_view = admin_url + "/user/view";
export const a_users_edit_url = a_users_url + "/edit";
export const a_crawler_url = admin_url + "/crawler";
export const a_crawler_edit_url = a_crawler_url + "/edit";
export const a_manage_url = admin_url + "/manage";
export const a_manage_edit_url = a_manage_url + "/edit";
export const a_category_url = admin_url + "/categories";
export const a_category_edit_url = a_category_url + "/edit";
export const a_media_url = admin_url + "/media";
export const a_media_edit_url = a_media_url + "/edit";
export const a_section_url = admin_url + "/sections";
export const a_section_edit_url = a_section_url + "/edit";
export const a_post_type_url = admin_url + "/post-types";
export const a_post_type_edit_url = a_post_type_url + "/edit";
export const a_source_url = admin_url + "/sources";
export const a_source_edit_url = a_source_url + "/edit";
export const a_channels_url = admin_url + "/channels";
export const a_velicine_url = admin_url + "/post-size";
export const a_stream_url = admin_url + "/stream";


export const RENAME_STRING = "rename";
export const REMOVE_STRING = "remove";
export const PURGE_STRING = "purge";
export const CREATE_STRING = "create";
export const SUCCESS_STRING = "success";
export const POST_INFORMATION_STRING = "post_information_string";
export const FORGOTPASSWORD_STRING = "forgot";
export const ERROR_STRING = "error";
export const MIN_FOLDER_NAME_LENGTH = 5;
export const MAX_FOLDER_NAME_LENGTH = 20;
export const PRIVACY_STRING = "Privacy";
export const LEGAL_STRING = "Legal";
export const HELP_STRING = "Help";

export const INTERNET_STRING = "internet";
export const PDF_STRING = "stampani";
export const ELECTRONIC_STRING = "elektronski";

export const TOAST_PLACEMENT = "bottom-center";
export const TOAST_TIMEOUT = 4000;

export const HeaderButtonString = "TOOLTIP.HEADER.BUTTON.";

export const FOLDERS_URL = ARHIVA_REST_URL + "api/folder/get";
export const DELETE_FOLDER_URL = ARHIVA_REST_URL + "api/folder/delete";
export const CREATE_FOLDER_URL = ARHIVA_REST_URL + "api/folder/create";
export const RENAME_FOLDER_URL = ARHIVA_REST_URL + "api/folder/rename";
export const PURGE_FOLDER_URL = ARHIVA_REST_URL + "api/folder/purge";
export const ADD_POST_URL = ARHIVA_REST_URL + "api/folder/add-post";
export const REMOVE_POST_URL = ARHIVA_REST_URL + "api/folder/delete-post";

export const GET_ALL_CRAWLERS = ARHIVA_REST_URL + "api/crawler/list";
export const GET_ALL_CRAWLER_RULE_TYPES =
  ARHIVA_REST_URL + "api/crawler-rule/list";
export const GET_ALL_CRAWLER_NETWORK_TYPES =
  ARHIVA_REST_URL + "api/crawler-type/list";
export const GET_CRAWLER_BY_ID = ARHIVA_REST_URL + "api/crawler/rule/";
export const SAVE_CRAWLER_URL = ARHIVA_REST_URL + "api/crawler/store";
export const EDIT_CRAWLER_URL = ARHIVA_REST_URL + "api/crawler/update";
export const DELETE_CRAWLER_URL = ARHIVA_REST_URL + "api/crawler/delete/";
export const PUBLISH_MULTIPLE_CRAWLER_URL =
  ARHIVA_REST_URL + "api/crawler/bulk-status-update";
export const DELETE_MULTIPLE_CRAWLERS_URL =
  ARHIVA_REST_URL + "api/crawler/bulk-delete";
export const GET_RUNNING_CRAWLERS = ARHIVA_REST_URL + "api/crawler/running-ids";

export const START_CRAWLER_MANUALLY_URL = ARHIVA_REST_URL + "api/crawler/start";
export const GET_CRAWLER_SETTINGS_URL =
  ARHIVA_REST_URL + "api/crawler-setting/list";
export const UPDATE_CRAWLER_SETTINGS_URL =
  ARHIVA_REST_URL + "api/crawler-setting/update";
export const CREATE_CRAWLER_SETTINGS_URL =
  ARHIVA_REST_URL + "api/crawler-setting/store";

export const GET_ALL_POSTS_URL = ARHIVA_REST_URL + "api/posts/list";
export const CREATE_POST_URL = ARHIVA_REST_URL + "api/posts/create";
export const EDIT_POST_URL = ARHIVA_REST_URL + "api/posts/update";
export const DELETE_POST_URL = ARHIVA_REST_URL + "api/post/delete/";

export const DELETE_MULTIPLE_POSTS_URL =
  ARHIVA_REST_URL + "api/post/bulk-delete";
export const GET_POST_TYPES_URL = ARHIVA_REST_URL + "api/post-type/list";
export const SAVE_POST_TYPE_URL = ARHIVA_REST_URL + "api/post-type/create";
export const EDIT_POST_TYPE_URL = ARHIVA_REST_URL + "api/post-type/update";
export const DELETE_POST_TYPE_URL = ARHIVA_REST_URL + "api/post-type/delete/";
export const DELETE_MULTIPLE_POST_TYPES_URL =
  ARHIVA_REST_URL + "api/post-type/bulk-delete";
export const GET_MEDIA_LIST_URL = ARHIVA_REST_URL + "api/media/list";
export const SAVE_MEDIA_URL = ARHIVA_REST_URL + "api/media/add";
export const EDIT_MEDIA_URL = ARHIVA_REST_URL + "api/media/update";
export const DELETE_MEDIA_URL = ARHIVA_REST_URL + "api/media/delete/";
export const DELETE_MULTIPLE_MEDIA_URL =
  ARHIVA_REST_URL + "api/media/bulk-delete";
export const GET_SECTION_LIST_URL = ARHIVA_REST_URL + "api/section/list";
