import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import { ArhivaPage } from "./pages/ArhivaPage";
import { FoldersPage } from "./pages/FoldersPage";
import { KlipingPage } from "./pages/KlipingPage";
import LegalPage from "./modules/Other/pages/LegalPage";
import PrivacyPage from "./modules/Other/pages/PrivacyPage";
import ContactPage from "./modules/Other/pages/ContactPage";
import HelpcenterPage from "./modules/Other/pages/HelpcenterPage";
import { ProfileOverview } from "./modules/Profile";
import { View } from "./modules/Admin/Users/View";
import {
  archive_url,
  clipping_media_url,
  folders_url,
  admin_url,
  error_url,
  legal_url,
  privacy_url,
  helpcenter_url,
  dashboard_url,
  userprofile_url,
  contactpage_url,
  a_users_url_view,
} from "../_metronic/_helpers";
import SingleFolder from "./modules/ECommerce/pages/folders/SingleFolder";
import AdminPage from "./modules/Admin";
import { QueryClient, QueryClientProvider } from "react-query";
import { Channels } from "./pages/Channels";

export default function BasePage() {
  // useEffect(() => {
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  });

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <QueryClientProvider client={queryClient}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          
          <ContentRoute path={legal_url} component={LegalPage} />
          <ContentRoute path={privacy_url} component={PrivacyPage} />
          <ContentRoute path={contactpage_url} component={ContactPage} />
          <ContentRoute path={helpcenter_url} component={HelpcenterPage} />
          <ContentRoute path={dashboard_url} component={DashboardPage} />
          <ContentRoute path={userprofile_url} component={ProfileOverview} />
          <ContentRoute path={admin_url} component={AdminPage} />
          <ContentRoute exact path={`/${archive_url}`} component={ArhivaPage} />
          <ContentRoute
            exact
            path={`/${clipping_media_url}`}
            component={KlipingPage}
          />
          <ContentRoute
            exact
            path={`/${folders_url}/:id`}
            component={FoldersPage}
          />
          <ContentRoute
            path={`/${folders_url}/:foldertype/:id`}
            component={SingleFolder}
          />
          <Redirect to={error_url} />
        </Switch>
      </QueryClientProvider>
    </Suspense>
  );
}
