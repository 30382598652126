import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import * as moment from "moment";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import ProfileService from "../../Profile/service";
import {
  GET_SECTION_LIST_URL,
  GET_POST_TYPES_URL,
  GET_MEDIA_LIST_URL,
  a_section_edit_url,
} from "../../../../_metronic/_helpers/Constants";
import { Table } from "../shared";
import { useSelector } from "react-redux";

import { sortCaret } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import {
  ActionsColumnFormatter,
  TitleColumnFormatter,
} from "../Policy/formatters";
import { useHistory } from "react-router-dom";
import DeleteModal from "../shared/DeleteModal";
import MultipleDeleteModal from "../shared/MultipleDeleteModal";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { useIntl } from "react-intl";
import { getSectionsData } from "../../../_redux/sections/actions";
import CustomDatePicker from "../shared/DatePicker/CustomDatePicker";

const SectionsPage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const service = new ProfileService();
  const [page, setPage] = useState(1);
  const [allIds, setAllIds] = useState([]);

  const [filterBy, setFilterBy] = useState({
    type: "All",
    media: "All",
    created_at: "All",
  });

  const [orderBy, setOrderBy] = useState({
    order_by: "",
    order_direction: "",
  });

  const [sizePerPage, setSizePerPage] = useState(20);

  const history = useHistory();

  const [currentSection, setCurrentSection] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [
    isMultipleDeleteModalVisible,
    setMultipleIsDeleteModalVisible,
  ] = useState(false);
  const [currentSections, setCurrentSections] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [data, setData] = useState([]);
  const sectionsData = useSelector((state) => state?.sections?.Index ?? []);
  const showLoader = useSelector((state) => state?.sections?.showLoader ?? []);
  const [dateValue, setDateValue] = useState(null);

  useEffect(() => {
    if (dateValue) {
      setFilterBy({
        ...filterBy,
        created_at: moment(dateValue).format("YYYY-MM-DD"),
      });
    }
  }, [dateValue]);

  useEffect(() => {
    setData(sectionsData);
  }, [sectionsData]);

  const fetchSections = async (page) => {
    let data = {
      page: page - 1,
      size: sizePerPage,
      // date: dateValue ?? null,
      type: filterBy.type === "All" ? null : filterBy.type,
      media: filterBy.media === "All" ? null : filterBy.media,
      created_at:
        filterBy.created_at === "All" || filterBy.created_at === "Invalid date"
          ? null
          : filterBy.created_at,
      order_by: orderBy.order_by === "" ? null : orderBy.order_by,
      order_direction:
        orderBy.order_direction === "" ? null : orderBy.order_direction,
    };
    for (let key in data) {
      if (data[key] === null) {
        delete data[key];
      }
    }

    dispatch(getSectionsData(data));

    // const response = await service.post(GET_SECTION_LIST_URL, {
    //   page: page - 1,
    //   size: sizePerPage,
    //   type: filterBy.type === "All" ? null : filterBy.type,
    //   media: filterBy.media === "All" ? null : filterBy.media,
    //   created_at:
    //     filterBy.created_at === "All" || filterBy.created_at === "Invalid date"
    //       ? null
    //       : filterBy.created_at,
    //   order_by: orderBy.order_by === "" ? null : orderBy.order_by,
    //   order_direction:
    //     orderBy.order_direction === "" ? null : orderBy.order_direction,
    // });
    // return response.data;
  };

  const { isLoading, isFetching } = useQuery(
    [page, filterBy, orderBy, sizePerPage],
    () => fetchSections(page),
    { keepPreviousData: true }
  );


  const onSort = (field, order) => {
    const sortField = (field) => {
      switch (field) {
        case "type.name":
          return "type";
        case "media.name":
          return "media";
        default:
          return field;
      }
    };
    setOrderBy({
      order_by: sortField(field),
      order_direction: order,
    });
  };

  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "name",
      text: intl.formatMessage({ id: "TH_NAME" }),
      sort: true,
      sortCaret: sortCaret,
      // formatter: TitleColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_section_edit_url}/${row.id}`);
        },
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "type.name",
      text: intl.formatMessage({ id: "TH_TYPE" }),
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "media.name",
      text: intl.formatMessage({ id: "TH_MEDIA" }),
      headerStyle: { verticalAlign: "initial" },
      sort: true,
      sortCaret: sortCaret,
      onSort: onSort,
    },
    {
      dataField: "created_at",
      text: intl.formatMessage({ id: "TH_CREATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "updated_at",
      text: intl.formatMessage({ id: "TH_UPDATED" }),
      sort: true,
      sortCaret: sortCaret,
      formatter: (val) => {
        return moment(val).format("DD.MM.yyyy HH:mm:ss");
      },
      onSort: onSort,
      headerStyle: { verticalAlign: "initial" },
    },
    {
      dataField: "action",
      text: intl.formatMessage({ id: "TH_ACTIONS" }),
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditModal: (row) => {
          history.push(`${a_section_edit_url}/${row.id}`);
        },
        openDeleteModal: (row) => {
          setCurrentSection(row);
          setIsDeleteModalVisible(true);
        },
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
      headerStyle: { verticalAlign: "initial" },
    },
  ];

  // if (isLoading) return null;
  const paginationOptions = {
    totalSize: data?.totalCount ?? 0,
    sizePerPage,
    page,
  };

  const deleteSelectedItems = (selectedIds) => {
    setMultipleIsDeleteModalVisible(true);
    setCurrentSections(selectedIds);
  };

  const searchByCreatedDate = (date) => {
    setStartDate(date);
    setFilterBy({
      ...filterBy,
      created_at: moment(date).format("YYYY-MM-DD"),
    });
  };

  return (
    <Card>
      <DeleteModal
        isVisible={isDeleteModalVisible}
        setIsVisible={setIsDeleteModalVisible}
        currentItem={currentSection}
        setCurrentItem={setCurrentSection}
        itemType="section"
      />
      <MultipleDeleteModal
        isVisible={isMultipleDeleteModalVisible}
        setIsVisible={setMultipleIsDeleteModalVisible}
        currentItems={currentSections}
        setCurrentItems={setCurrentSections}
        setSelectedRows={setAllIds}
        itemType="section"
      />
      <CardHeader title={intl.formatMessage({ id: "MENU.SECTION" })} sticky>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary ml-5"
            onClick={() => {
              history.push(a_section_edit_url);
            }}
          >
            {intl.formatMessage({ id: "MENU.NEW" })}{" "}
            {intl.formatMessage({ id: "MENU.SECTION" })}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <div className="form-group row">
          <div className="col-lg-2">
            {/* {!postTypes.isLoading && ( */}
            <select
              className="form-control"
              name="type"
              placeholder="Filter by Types"
              onChange={(e) => {
                setFilterBy({
                  ...filterBy,
                  type: e.target.value,
                });
                // setPage(1);
              }}
              value={filterBy.type}
            >
              <option value={"All"}>All</option>
              <option value={"privacy"}>Privacy</option>
              <option value={"legacy"}>Legacy</option>
              <option value={"faq"}>FAQ</option>

            </select>
            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b>{" "}
              {intl.formatMessage({ id: "TH_TYPE" })}
            </small>
          </div>
         
          <div className="col-lg-2">
            <CustomDatePicker
              dateValue={dateValue}
              id={"sections"}
              setDateValue={setDateValue}
            />

            <small className="form-text text-muted">
              <b>{intl.formatMessage({ id: "TH.FILTER_BY" })}</b> Created Date
            </small>
          </div>
        </div>
        {allIds.length > 1 && (
          <div className="form-group">
            <p>Selected records count:{allIds.length}</p>
            <button
              type="button"
              className="btn btn-danger font-weight-bolder font-size-sm"
              onClick={() => deleteSelectedItems(allIds)}
            >
              <i className="fa fa-trash"></i>
              {intl.formatMessage({ id: "COMMON.Delete" })}
            </button>
          </div>
        )}

        <Table
          // showLoader={showLoader}
          data={data?.data ?? []}
          columns={columns}
          pagiOptions={paginationOptions}
          setPage={setPage}
          isFetching={isFetching}
          setSizePerPage={setSizePerPage}
          selectRowOptions={{
            allIds,
            setAllIds,
          }}
          intl={intl}
        />
      </CardBody>
    </Card>
  );
};

export default SectionsPage;
