/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  checkIsActive,
  admin_url,
  a_crawler_url,
  a_manage_url,
  a_policy_url,
  a_users_url,
  a_institute_url,
  a_institute_activity_url,
  a_subject_url,
  dashboard_url,
  a_category_url,
  a_media_url,
  a_section_url,
  a_post_type_url,
  a_source_url,
  a_channels_url,
  a_velicine_url,
  a_stream_url,
} from "../../../../_helpers";
import { useIntl } from "react-intl";
import AsideFolder from "../../../../../app/modules/ECommerce/pages/folders/folders-aside/AsideFolder";
export function AsideMenuList({ layoutProps, menuProps }) {
  const intl = useIntl();
  const [showAll, setShowAll] = useState(false);
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? " menu-item-open " : "";
  };
  const {
    menuConfig: { data },
  } = menuProps;
  const [folders, setFolders] = useState({});
  const {
    user: { account_information },
  } = useSelector((state) => state.auth);
  useEffect(() => {
    setFolders(data);
  }, [data]);

  useEffect(() => {
    setShowAll(true);
  }, [location.pathname]);

  const isAdmin = account_information?.roles?.includes("administrator");
  const isManager = account_information?.roles?.includes("manager");
  const showAdminPage = isAdmin || isManager;

  const admin_menu_list = [
    {
      sectionName:"Manage",
      sectionsArray:[
        {url:a_manage_url, fname:"posts"},
        {url:a_users_url, fname:"MENU.USERS"},
        {url:a_institute_url, fname:"Institutions"},
        {url:a_media_url, fname:"MENU.MEDIA"},
        {url:a_category_url, fname:"MENU.CATEGORY"},
        {url:a_institute_activity_url, fname:"MENU.INSTITUTIONS.ACTIVITY"},
        {url:a_subject_url, fname:"Subjects"},
        {url:a_section_url, fname:"MENU.SECTION"},
        {url:a_post_type_url, fname:"MENU.POST-TYPE"},
        {url:a_source_url, fname:"MENU.SOURCE"},
        {url:a_policy_url, fname:"MENU.POLICY"},
        {url:a_stream_url, fname:"IPTV"}
      ]
    },
    {
      sectionName:"RTC",
      sectionsArray:[
        {url:a_channels_url, fname:"MENU.CHANNEL"},
        {url:a_velicine_url, fname:"post_size"},
        
      ]
    }
  ]

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive(`${dashboard_url}`)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={`${dashboard_url}`}>
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Home/Home-heart.svg")}
              />
            </span>
            <span className="menu-text">
              {intl.formatMessage({ id: "MENU.DASHBOARD" })}
            </span>
          </NavLink>
        </li>
        {showAdminPage && (
          <>
          <li className="menu-section ">
            <h4 className="menu-text">
              {intl.formatMessage({ id: "MENU.ADMIN_SECTION" })}
            </h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          <li
            className={`menu-item  menu-item-paren ${getMenuItemActive(
              `${admin_url}`
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link menu-toggle" to={`${admin_url}`}>
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Spy.svg")} />
              </span>
              <span className="menu-text">
                {intl.formatMessage({ id: "MENU.ADMIN" })}
              </span>
              <i className="menu-arrow" />
            </NavLink>
            {admin_menu_list?.map((section)=>{
              return <>
                <div className="menu-submenu"  kt-hidden-height={400}>
              <i className="menu-arrow" />
              <ul className="menu-subnav">
              
                <li
                  className="menu-item menu-item-submenu menu-item-open"
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <a href="" className="menu-link menu-toggle">
                    <i className="menu-bullet menu-bullet-line">
                      <span />
                    </i>
                    <span className="menu-text">
                      {intl.formatHTMLMessage({ id: section.sectionName })}
                    </span>
                    <i className="menu-arrow" />
                  </a>
                  <div className="menu-submenu ">
                    <i className="menu-arrow" />
                    <ul className="menu-subnav">
                      {isAdmin && (
                        <>
                        {section?.sectionsArray?.map((listItem)=>{
                          return <>
                            <li
                          className={`menu-item menu-item-submenu ${getMenuItemActive(
                            `${listItem.url}`,
                            true
                          )}`}
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                        >
                          <NavLink
                            className="menu-link menu-toggle"
                            to={`${listItem.url}`}
                          >
                            <i className="menu-bullet menu-bullet-dot">
                              <span />
                            </i>
                            <span className="menu-text">
                              {intl.formatMessage({ id: listItem.fname })}
                            </span>
                          </NavLink>
                        </li>
                      </>
                        })}
                        </>
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
              </>
            })
            }
         
          </li>
          </>
        )}
        <li className="menu-section ">
          <h4 className="menu-text">
            {intl.formatMessage({ id: "MENU.USER_FOLDERS" })}
          </h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {showAll && (
          <>
            {Object.keys(folders).map((type, index) => {
              return <AsideFolder folders={folders} type={type} key={index} />;
            })}
          </>
        )}
      </ul>
    </>
  );
}