// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useIntl } from "react-intl";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { getSelectRow } from "../../Policy/helpers";
import Lottie from "react-lottie";
import animationData from "../lotties/animation.json";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Table = ({
  data,
  columns,
  pagiOptions,
  setPage,
  isFetching,
  show,
  setSizePerPage,
  selectRowOptions,
  intl,
  showLoader,
}) => {
  const customTotal = (from, to, size) => {
    return (
      <span className="react-bootstrap-table-pagination-total">
        {intl.formatMessage({ id: "PAGINATION.SHOWING_ROWS" })}
        {from} {intl.formatMessage({ id: "PAGINATION.SHOWING_ROWS_TO" })} {to}
      </span>
    );
  };
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [
      { text: "5", value: 5 },
      { text: "10", value: 10 },
      { text: "20", value: 20 },
      { text: "30", value: 30 },
      { text: "50", value: 50 },
    ],

    ...pagiOptions,
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {showLoader ? (
        <Lottie options={defaultOptions} height={400} width={400} />
      ) : (
        <PaginationProvider
          pagination={paginationFactory(paginationOptions)}
          keyField="id"
          data={data}
          columns={columns}
        >
          {({ paginationProps, paginationTableProps }) => {
            return (
              <Pagination
                isLoading={isFetching}
                show={show}
                paginationProps={paginationProps}
              >
                <BootstrapTable
                  wrapperClasses="table-responsive"
                  classes="table table-head-custom table-vertical-center overflow-hidden"
                  bootstrap4
                  bordered={false}
                  remote
                  keyField="id"
                  data={data}
                  columns={columns}
                  // defaultSorted={uiHelpers.defaultSorted}
                  onTableChange={(_, rest) => {
                    if (rest.page) {
                      setPage(rest.page);
                    }
                    if (rest.sizePerPage !== paginationOptions.sizePerPage) {
                      setSizePerPage(rest.sizePerPage);
                    }
                  }}
                  selectRow={getSelectRow({
                    entities: data,
                    ids: selectRowOptions?.allIds,
                    setIds: selectRowOptions?.setAllIds,
                  })}
                  {...paginationTableProps}
                >
                  {data && data.length === 0 && <div>No records found</div>}
                </BootstrapTable>
              </Pagination>
            );
          }}
        </PaginationProvider>
      )}
    </>
  );
};

export default Table;
